import { Caption2SemiBold } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

// FooterLinks keys must match the keys in en.json to display the link label
import { FOOTER_LINKS } from '~/constants'
import { EventClick, EventClickNamesValues, EventScreenName, EventScreenNameValues } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

interface FooterProps {
  className?: string
  // showLinks will show the links with the keys provided
  // if not provided, all links will be shown
  showLinks?: Array<FooterLinkKeys>
}

type FooterLinkKeys = keyof typeof FOOTER_LINKS
type FooterLinkvalues = ValueOf<typeof FOOTER_LINKS>
const FooterLinks = Object.entries(FOOTER_LINKS) as [FooterLinkKeys, FooterLinkvalues][]

const FooterEvents: {
  [k in FooterLinkKeys]: {
    clickName: EventClickNamesValues
    destination: EventScreenNameValues
  }
} = {
  termsOfService: {
    clickName: EventClick.names.TermsOfService,
    destination: EventScreenName.TermsOfService,
  },
  privacyPolicy: {
    clickName: EventClick.names.PrivacyPolicy,
    destination: EventScreenName.PrivacyPolicy,
  },
  californiaPrivacy: {
    clickName: EventClick.names.CaliforniaPrivacy,
    destination: EventScreenName.PrivacyPolicy,
  },
  help: {
    clickName: EventClick.names.VisitHelpdesk,
    destination: EventScreenName.HelpCenter,
  },
} as const

const Footer: React.FC<FooterProps> = ({ className, showLinks }) => {
  const t = useTranslationForNamespace('common')
  const year = new Date().getFullYear()
  const { trackCta } = useTrackClick()

  const handleClick = (label: FooterLinkKeys) => {
    trackCta({
      click_name: FooterEvents[label].clickName,
      destination: FooterEvents[label].destination,
      text: label,
      type: EventClick.types.Link,
    })
  }

  const FilteredLinks = showLinks ? FooterLinks.filter(([key]) => showLinks.includes(key)) : FooterLinks

  return (
    <FooterContainer className={className}>
      <Copyright>© {year} LUMOS LABS, INC.</Copyright>
      <Links>
        {FilteredLinks.map(([label, link]) => (
          <LinkContainer key={label} onClick={() => handleClick(label)}>
            <a href={link}>
              <LinkText>{t(`footer.${label}`)}</LinkText>
            </a>
          </LinkContainer>
        ))}
      </Links>
    </FooterContainer>
  )
}

const Copyright = styled(Caption2SemiBold)`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: block;
    padding: 15px;
  }
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
  padding-top: 5px;
  text-transform: uppercase;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px;
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
  @media (max-width: 660px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }
`

const LinkContainer = styled.span`
  padding: 0 16px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: block;
    text-align: center;
  }
`

const Links = styled.div`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`

const LinkText = styled(Caption2SemiBold)`
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
`

export default Footer
