import { useContext, useEffect, useState } from 'react'

import type { Card, ClassicCard, ImageOnly } from '@braze/web-sdk'
import { BodyText3, Subheading2 } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import CardComponent from '~/components/ui/Card'
import { MarketingContext } from '~/context/MarketingContext'
import CloseX from '~/images/icons/SystemOutlined/Close.svg'

const BrazeContentCardWrapper = styled.div`
  position: relative;

  .image {
    width: 100%;
    display: block;
  }

  .close-button {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
  }
`

const BrazeCardClickableContent = styled.div`
  cursor: pointer;
`

const HoverableCard = styled(CardComponent)`
  &:hover {
    border: 1px solid transparent;
    box-shadow: ${(props) => props.theme.effects.boxShadow.outset[2]};
  }
  :not(:hover) {
    border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
    box-shadow: transparent 0px 0px 0px 1px;
  }
`

const BrazeClassicCardLayout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BrazeClassicCardImage = styled.div`
  width: 64px;
  margin: 12px 16px;
`

const BrazeClassicCardTitle = styled(Subheading2)`
  display: block;
`

const BrazeClassicCardDescription = styled(BodyText3)`
  display: block;
`

const BrazeClassicCardTextContainer = styled.div`
  margin: 8px;
`

export const BrazeContentCard = ({
  card,
  click,
  dismiss,
}: {
  card: Card
  click?: (card: Card) => void
  dismiss?: (card: Card) => void
}) => {
  const {
    contentCards,
    setContentCards,
    handleBrazeAction,
    logCardClickEvent,
    logCardImpressionEvent,
    logCardDismissalEvent,
  } = useContext(MarketingContext)
  const [didLogImpression, setDidLogImpression] = useState(false)

  useEffect(() => {
    if (!didLogImpression) {
      logCardImpressionEvent(card, true)
      setDidLogImpression(true)
    }
  }, [card, didLogImpression, logCardImpressionEvent])

  useEffect(() => {
    card.subscribeToClickedEvent(() => click && click(card))
    card.subscribeToDismissedEvent(() => dismiss && dismiss(card))

    return function cleanup() {
      card.removeAllSubscriptions()
    }
  }, [card, click, dismiss])

  const onClick = () => {
    logCardClickEvent(card, true)
    if ('url' in card && typeof card.url === 'string') {
      // Required to handle Braze actions with `brazeActions://` prefix
      handleBrazeAction(card.url, true)
    }
  }
  const onDismiss = () => {
    setContentCards(contentCards?.filter((c: Card) => c !== card))
    logCardDismissalEvent(card)
  }

  if (card.isControl) {
    return null
  }

  if (isImageOnly(card) && !card.dismissed) {
    return (
      <BrazeContentCardWrapper>
        <div>
          <BrazeCardClickableContent title={card.linkText} onClick={onClick}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img className='image' src={card.imageUrl} alt={card.linkText} width='100%' />
          </BrazeCardClickableContent>
          {card.pinned ? null : <CloseX title='Close' className='close-button' onClick={onDismiss} fontSize={'32px'} />}
        </div>
      </BrazeContentCardWrapper>
    )
  }

  if (isClassicCard(card) && !card.dismissed) {
    return (
      <BrazeContentCardWrapper>
        <BrazeCardClickableContent title={card.linkText} onClick={onClick}>
          <HoverableCard>
            <BrazeClassicCardLayout>
              <BrazeClassicCardImage>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img className='thumbnail' src={card.imageUrl} alt={card.linkText} width='100%' />
                {card.pinned ? null : (
                  <CloseX title='Close' className='close-button' onClick={onDismiss} fontSize={'32px'} />
                )}
              </BrazeClassicCardImage>
              <BrazeClassicCardTextContainer>
                <BrazeClassicCardTitle>{card.title}</BrazeClassicCardTitle>
                <BrazeClassicCardDescription>{card.description}</BrazeClassicCardDescription>
              </BrazeClassicCardTextContainer>
            </BrazeClassicCardLayout>
          </HoverableCard>
        </BrazeCardClickableContent>
      </BrazeContentCardWrapper>
    )
  }

  return null
}

function isClassicCard(card: Card): card is ClassicCard {
  return hasNonEmptyProperties(card, ['imageUrl', 'url', 'title', 'description'])
}

function isImageOnly(card: Card): card is ImageOnly {
  if (isClassicCard(card)) {
    return false
  }
  return hasNonEmptyProperties(card, ['imageUrl', 'url'])
}

function hasNonEmptyProperties(card: Card, properties: string[]): boolean {
  return properties.every((property) => {
    return Object.hasOwn(card, property) && card[property as keyof Card]
  })
}
